<template>
  <div
    class="hk-wrapper"
    data-layout="horizontal"
    data-layout-style="default"
    data-menu="light"
    data-footer="simple"
  >
    <!-- Top Navbar -->
    <Navbar />
    <!-- /Top Navbar -->

    <!-- Main Content -->
    <div class="hk-pg-wrapper pb-0">
      <slot />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<style></style>
